import React from "react"
import "./SpaceLinks.scss"

import googleMeetIcon from "./google-meet.svg"

function SpaceLinks({ hideTitle = true, align = "center", space = {} }) {
  if (!space.link_to_google_meet) return null

  return (
    <div className="SpaceLinks">
      {hideTitle ? "" : <h4>Links</h4>}
      <div className={`SpaceLinks__links SpaceLinks__links--${align}`}>
        <a
          href={`https://meet.google.com/lookup/${space.name}`}
          title="Go to meeting"
          target="_blank"
          rel="noreferrer"
          className="SpaceLinks__link"
        >
          <img src={googleMeetIcon} />
        </a>
      </div>
    </div>
  )
}

export default SpaceLinks
