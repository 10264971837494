import React, { useEffect } from "react"
import { useSelector } from "react-redux"

import Countdown from "~/components/Countdown/Countdown"
import { formattedDuration } from "~/utils/TimeFormatter"
import Button from "~/components/Button/Button"
import axios from "axios"
import { postEvent } from "~/utils/EventManager"

import "./Timer.scss"
import { setLuxaforLight } from "../../utils/Luxafor"
import { getUser } from "~/store"

function Timer(props) {
  const user = useSelector(getUser)
  const { spaceName, timer, onATimerHasEnded } = props

  const updateDocumentTitle = (durationLeft) => {
    document.title =
      durationLeft == null ? "Pomodoro" : formattedDuration(durationLeft)
  }

  useEffect(() => {
    setLuxaforLight(user, timer.break ? "00ff00" : "ff0000")

    return () => {
      setLuxaforLight(user, "000000")
      updateDocumentTitle(null)
    }
  }, [])

  const cancelCurrentTimer = () => {
    axios
      .delete(`/api/spaces/${spaceName}/timers/current`)
      .then((_response) => {
        postEvent(spaceName, "timerCancelled")
        updateDocumentTitle(null)
        props.onCancelled()
      })
  }

  return (
    <div className="Timer">
      <span className="Timer__headline">
        {timer.break ? "Relax for" : "Stay focused for"}
      </span>
      <div className="Timer__countdown">
        <Countdown
          spaceName={spaceName}
          onTick={updateDocumentTitle}
          timer={timer}
          onCountDownEnd={onATimerHasEnded}
        />
      </div>
      <Button
        text="Stop timer"
        style={`outline Timer__cycle--${timer.break ? "break" : "work"}`}
        onClick={cancelCurrentTimer}
      />
    </div>
  )
}

Timer.defaultProps = {
  onCancelled: () => {},
}

export default Timer
