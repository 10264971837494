import React, { useEffect, useState } from "react"

import { calculateDurationLeft, calculatePercentage } from "~/utils/TimerUtils"
import { formattedDuration } from "~/utils/TimeFormatter"
import { postEvent } from "~/utils/EventManager"
import { useDispatch } from "react-redux"

import "./Countdown.scss"
import { switchCycle, getUser } from "~/store"
import { useSelector } from "react-redux"

import { playAlarm } from "~/utils/AlarmUtils"

function Countdown(props) {
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const { timer, onTick, spaceName, onCountDownEnd } = props
  const [durationLeft, setDurationLeft] = useState(
    timer ? calculateDurationLeft(timer) : 0
  )

  const playAlertSound = () => {
    if (user.alert) {
      playAlarm(user.alert_volume)
    }
  }

  const onEnded = () => {
    if (user.space_name == spaceName) {
      onCountDownEnd(true)
      dispatch(switchCycle())
      playAlertSound()
    }
  }

  useEffect(() => {
    let timerAtZero = false
    const interval = setInterval(
      (function updateDuration() {
        const durationLeftNow = parseInt(calculateDurationLeft(timer))
        setDurationLeft(durationLeftNow)
        onTick(durationLeftNow)
        if (!timerAtZero && durationLeftNow == 0) {
          timerAtZero = true
          onEnded()
          postEvent(spaceName, "timerEnded", { timer_id: timer.id })
        }
        return updateDuration
      })(),
      1000
    )

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <>
      <div
        className={`Countdown__progress Countdown__progress--${
          timer.break ? "break" : "work"
        }`}
        style={{ "--percentage": calculatePercentage(timer) + "%" }}
      />
      <div className="Countdown__duration">
        {formattedDuration(durationLeft)}
      </div>
    </>
  )
}

Countdown.defaultProps = {
  onTick: () => {},
  timer: null,
  spaceName: null,
  showBackground: false,
}

export default Countdown
