import React from "react"
import axios from "axios"
import { useSelector } from "react-redux"

import Heading from "~/components/Heading/Heading"
import TimerOption from "~/components/TimerOption/TimerOption"
import { postEvent } from "~/utils/EventManager"

import "./TimerPicker.scss"
import { getCycle } from "../../store"

function TimerPicker(props) {
  const cycle = useSelector(getCycle)
  const cycleIsBreak = cycle === "break"

  const minutes = {
    work: [20, 25, 35, 40],
    break: [5, 10, 30],
  }

  const minutesForCycle = cycleIsBreak ? minutes.break : minutes.work

  const createTimer = (minutes) => {
    const params = {
      timer: {
        duration: minutes * 60,
        break: cycleIsBreak,
      },
    }

    axios
      .post(`/api/spaces/${props.spaceName}/timers`, params)
      .then((_response) => {
        postEvent(props.spaceName, "timerCreated")
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <div className="TimerPicker">
      <Heading
        text={cycleIsBreak ? "Let's take a break for" : "Let's work for"}
      />
      <div className="TimerPicker__list">
        {minutesForCycle.map((minute, index) => {
          return (
            <TimerOption
              minutes={minute}
              key={index}
              cycleIsBreak={cycleIsBreak}
              onClick={createTimer}
            />
          )
        })}
      </div>
    </div>
  )
}

export default TimerPicker
